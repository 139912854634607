<script>
import ApiService from "@services/api.service";

export default {
  name: "NewsGroup",
  data() {
    return {
      table: {
        sortBy: "description",
        fields: [
          { key: "description", sortable: true, label: "News Group" },
          { key: "active", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        filter: null
      },

      newsGroup: {
        description: null
      },
      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    },
    editMode: function () {
      return this.newsGroup.code != null;
    }
  },
  created() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("news/group")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    openDetail(selectedRow) {
      if (selectedRow) this.newsGroup = { ...selectedRow };
      this.showModal = true;
    },
    closeDetail() {
      this.showModal = false;
    },
    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        ApiService.post("news/group", this.newsGroup)
          .then(() => {
            this.loadTable();
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        ApiService.put("news/group", this.newsGroup)
          .then(() => {
            this.loadTable();
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    toggle(code) {
      this.changeLoader(true);
      let qs = {
        code: code
      };
      ApiService.patch("news/group", null, qs)
        .then(resp => {
          this.loadTable();
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    clearForm() {
      this.newsGroup = {
        description: null
      };
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div id="mount-point"></div>
      <div class="d-flex mb-2">
        <b-btn variant="outline-primary" @click.stop="openDetail(null)">Create</b-btn>
        <div class="ml-auto">
          <b-input v-model="table.filter" placeholder="Filter" class="form-control text-right" />
        </div>
      </div>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        no-provider-filtering
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
      >
        <template #cell(active)="data">
          <div>
            <b-badge :variant="data.item.isActive ? 'success' : 'warning'" class="text-capitalize">
              {{ data.item.isActive ? "Active" : "Inactive" }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn class="btn btn-sm btn-wide mr-1" :class="data.item.isActive ? 'btn-secondary' : 'btn-info'" @click.stop="toggle(data.item.code)">{{
              data.item.isActive ? "Deactivate" : "Activate"
            }}</b-btn>
            <b-btn class="btn btn-sm btn-wide btn-warning mr-1" @click.stop="openDetail(data.item)">Edit</b-btn>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal v-model="showModal" centered size="lg" hide-footer :backdrop="true" @hidden="clearForm">
      <div class="px-3 py-2">
        <b-row>
          <b-col class="text-center">
            <h2>{{ !editMode ? `Create New News Group` : `Edit News Group` }}</h2>
          </b-col>
        </b-row>
        <hr />
        <b-form @submit.stop.prevent="save">
          <b-row class="mb-2">
            <b-col md="4">
              <label>News Group</label>
            </b-col>
            <b-col md="8">
              <b-input v-model="newsGroup.description" class="form-control" />
              <small>News Group</small>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-btn block variant="danger" @click.stop="showModal = false">Cancel</b-btn>
            </b-col>
            <b-col>
              <b-btn block type="submit" variant="success">Save</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
